import { useEffect, useState } from 'react';
import Stadium from '../../asset/bg-image.jpg';
import SignInFeature from './Components/SignIn';
import ResetPasswordFeature from './Components/ResetPassword';
import SignUpFeature from './Components/SignUp';
import ForgotPasswordFeature from './Components/ForgotPassword';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetSingeUserWithUsername } from '../../Redux/apiCalls';


const AuthenticationPage = () => {
  const [activeComponent, setActiveComponent] = useState('signIn');
  const { username } = useParams();
  const user = useSelector((state: any) => state.user.currentUser);
  
  const renderComponent = () => {
    switch (activeComponent) {
      case 'signIn':
        return <SignInFeature setActiveComponent={setActiveComponent} />;
      case 'resetPassword':
        return <ResetPasswordFeature setActiveComponent={setActiveComponent} />;
      case 'signUp':
        return <SignUpFeature setActiveComponent={setActiveComponent} referral={username} />;
      case 'forgotPassword':
        return <ForgotPasswordFeature setActiveComponent={setActiveComponent} />;
      default:
        return null;
    }
  };

  const isUserValid = async () => {
   try {
    const isUser = await GetSingeUserWithUsername(username);
    if(isUser) {
      setActiveComponent("signUp");
    }
    else {
      setActiveComponent("signIn");
    }
   } catch (error) {
    console.log(error);
    setActiveComponent("signIn");
   }
  }

  useEffect(() => {
    if(username) {
      isUserValid();
    }

   
  }, [])

  if(!user?.token){
    return (
      <div
        className="min-h-screen bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${Stadium})`,
        }}
      >
        {renderComponent()}
      </div>
    );
  }
  else {
    return <Navigate to="/loading-data" />
  }
  
};

export default AuthenticationPage;
