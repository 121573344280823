import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ModalContextType {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  isConsentOpen: boolean;
  openConsentModal: () => void;
  closeConsentModal: () => void;
  isPaymentOpen: boolean;
  openPaymentModal: () => void;
  closePaymentModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConsentOpen, setIsConsentModalOpen] = useState<boolean>(false)
  const [isPaymentOpen, setIsPaymentModalOpen] = useState<boolean>(false)

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openConsentModal = () => setIsConsentModalOpen(true);
  const closeConsentModal = () => setIsConsentModalOpen(false);
  const openPaymentModal = () => setIsPaymentModalOpen(true);
  const closePaymentModal = () => setIsPaymentModalOpen(false);

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, isConsentOpen, openConsentModal, closeConsentModal, isPaymentOpen, openPaymentModal, closePaymentModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
