import React, { useState } from 'react'
import UsersImage from '../asset/users12.png';
import { useSelector } from 'react-redux';


export const TotalReferrals = () => {
  const user = useSelector((state: any) => state.user.currentUser);
    const [copied, setCopied] = useState(false);
    const [textToCopy, setTextToCopy] = useState(`https://app.betracle.ai/signup/ref/${user.user.username}`)

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(textToCopy);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };
    return (
        <div className="mt-2 ">
        {/* New Section */}
        <div className="mt-0 bg-gradient-to-r from-[#150B22] to-[#042635] p-5 border-radius rounded-lg" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A' }}>
          <div className="text-left">
            <div className="flex items-center mb-3">
              <img src={UsersImage} alt="Icon" className="w-10 h-10 md:w-12 md:h-12 mr-3" />
              <p className="text-[0.9rem] md:text-[1rem] text-[#D9D9D9] font-[200]">Total Referrals</p>
            </div>
            <p className="text-[#FFFFFF] text-3xl md:text-4xl font-extrabold font-[Orbitron]  mb-3">{user.user.referralCount}</p>
            {/* <div className=" w-full"> */}
              <button 
              onClick={copyToClipboard} 
              disabled={copied}
              //disabled={true} 
              className="bg-[#00FF87] text-[#000000] px-4 py-2 rounded-lg  shadow-md flex items-center justify-center font-extrabold md:w-[200px]">
                <span className=" font-[900] text-[0.7rem] md:text-[0.9rem]">
                  
                  {copied ? 'Copied!' : 'Copy referral link'}
                </span>
              </button>
            {/* </div> */}

          </div>
        </div>
      </div>
    )
}
