import React, { useEffect, useState } from 'react';
import Tether from '../../asset/tether.png';
import EqualIcon from '../../asset/equal-box.png';
import RaxIcon from '../../asset/rax.png';
import SolarCard from '../../asset/solar_card-bold.png';
import { ToastContainer, toast } from 'react-toastify';
import { useModal } from './ModalContext';
import { NewPaymentTransaction } from '../../Redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { usePaystackPayment, PaystackButton } from 'react-paystack';
import { InitializePayment } from 'react-paystack/dist/types';
import { PAYSTACK_KEY } from '../../Utils/utils';
import { handleUpdatePredictionPoint } from '../../Redux/userRedux';

const modeOfPayment = [
    { value: 0, title: 'Card', image: SolarCard },
    { value: 0.004, title: 'USDT', image: Tether },
    { value: 100, title: 'RAX', image: RaxIcon },
]


const BuyPoints: React.FC<any> = ({ closeAddPaymentModal, isAddPaymentOpen }) => {
    const [activeTab, setActiveTab] = useState(-1);
    const [isPaymentValue, setPaymentValue] = useState(0);
    const [typeOfPayment, setTypeOfPayment] = useState(null);
    const [point, setPoint] = useState<any>('');
    const { isPaymentOpen, closePaymentModal } = useModal();

    const dispatch = useDispatch();


    const handleTabClick = (index: number, value: any, mode: any) => {
        let totalAmountToPay = 0;

        setActiveTab(index);
        setTypeOfPayment(mode);

        if (mode === 'Card') {
            totalAmountToPay = point * 500;
            setPaymentValue(totalAmountToPay);
        }

        if (mode === 'USDT') {
            totalAmountToPay = (0.004 * point * 500) / 1000;
            setPaymentValue(totalAmountToPay);
        }

        if (mode === 'RAX') {
            totalAmountToPay = (100 * point * 500) / 1000;
            setPaymentValue(totalAmountToPay);
        }


    };

    const userState = useSelector((state: any) => state.user.currentUser);
    
    const config = {
        reference: (new Date()).getTime().toString(),
        email: userState.user.email,
        amount: point * 500 * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: PAYSTACK_KEY,
        currency: "NGN",
    };

    
    const onSuccess = async (reference: any) => {
        // Implementation for whatever you want to do with reference and after success call.

        const payload = {
            point: point,
            userId: userState.user.id,
            transactionId: reference.trxref,
            status: reference.status,
        }

        const response = await NewPaymentTransaction(payload);

        if (response.message === 'Prediction points purchased successfully!') {
            setPoint(0);
            dispatch(handleUpdatePredictionPoint(response.newPredictionPoint));
        }

    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    }

    const initializePayment: InitializePayment = usePaystackPayment(config);

    const handlePayment = () => {

        if (point === 0 || point === null || point === '') {
            toast.warn("Please enter a valid amount")
        }
        else if (point < 10) {
            toast.warn("Minimum point to purchase is 10");
            setPoint(0);
        }
        else if(typeOfPayment === null) {
            toast.warn("Select a payment method");
        }
        else {
            if(typeOfPayment === 'Card') {
                initializePayment({ onSuccess, onClose });
            }
            else {
                toast.warn("Integration coming soon!");
            }
        }

    }

    const HandlePaymentModal = async () => {
        closePaymentModal();
        setPoint(0);
        setPaymentValue(0);
        setTypeOfPayment(null);
        setActiveTab(-1);
    }

    useEffect(() => {
        let totalAmountToPay = 0;

        if (typeOfPayment === 'Card') {
            totalAmountToPay = point * 500;
        }

        if (typeOfPayment === 'USDT') {
            totalAmountToPay = 0.004 * point * 500 / 1000;
        }

        if (typeOfPayment === 'RAX') {
            totalAmountToPay = 100 * point * 500 / 1000;
        }

        setPaymentValue(totalAmountToPay);
    }, [point, typeOfPayment]);

    return (
        <div>

            {isPaymentOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div onClick={HandlePaymentModal} className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"></div>

                    <ToastContainer autoClose={5000} />
                    <div className="modal-container  w-11/12 md:max-w-md mx-auto border-radius shadow-lg overflow-y-auto" style={{ zIndex: 9999, background: 'rgb(16 16 16 / 79%)' }}>
                        {/* Add your modal content here */}
                        <div className="modal-content py-4 text-left px-6">



                            <div className="my-6">
                                <div className="text-left mb-1">
                                    <span className='text-[#DBDBDB] text-[0.7rem]'>Min: 10 points (500/point)</span>
                                </div>
                                <input
                                    type="number"
                                    className="w-full h-12 px-4 py-2 focus:outline-none text-[#8D8D8D] border-[1px] border-[#2A2A2A] border-radius placeholder-[#545454]"
                                    placeholder='Enter points'
                                    style={{ background: 'rgb(20 20 20 / 79%)', fontSize: '12px' }}
                                    value={point}
                                    onChange={(e: any) => setPoint(e.target.value)}
                                />
                            </div>

                            <div className="mb-20 flex items-center">
                                <div className="w-10 h-10 mr-4">
                                    <img src={EqualIcon} alt="modal" className="w-full h-auto" />
                                </div>
                                <div className="">
                                    <div className="text-2xl font-normal text-[#00FF87]">
                                        {typeOfPayment === 'Card' && <span className="-top-2 -right-2 text-base mr-2">₦</span>}
                                        {isPaymentValue}
                                        <span className="-top-2 -right-2 text-base ml-2">{typeOfPayment === 'USDT' ? 'BTC' : typeOfPayment === 'RAX' ? 'RAX' : ''}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="my-6">
                                <span className="text-[1rem] font-[400] text-[#DBDBDB]">Select Method Of Payment</span>
                            </div>

                            <div className="my-6 mb-20">
                                <ul className="flex flex-wrap justify-around text-sm font-medium text-center">
                                    {modeOfPayment?.map((tab: any, index: number) => (
                                        <li key={index} className="mr-2 mb-4">
                                            <button
                                                className={`inline-block px-4 py-3 ${activeTab === index
                                                    ? 'text-[#D9D9D9] glass-card font-normal'
                                                    : 'text-[#D9D9D9] bg-[#151515] font-normal'
                                                    } w-[100px] md:w-[110px] flex items-center border-radius`}
                                                onClick={() => handleTabClick(index, tab.value, tab.title)}
                                                style={{ boxShadow: `${activeTab === index ? '4px 4px 0 0 #000000' : ''}` }}
                                            >
                                                <div className="w-6 h-6 mr-4">
                                                    <img src={tab.image} alt="modal" className="w-full h-auto" />
                                                </div>
                                                {tab.title}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>



                            <div className="mb-4 ">
                                <button
                                    onClick={handlePayment}

                                    className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[1rem] py-4 px-4 w-full border-radius"
                                >
                                    Pay
                                </button>
                            </div>




                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BuyPoints;
