import React, { useState } from 'react';
import Exclam from '../../asset/exclam.png';
import { handleConsent } from '../../Redux/userRedux';
import { useDispatch } from 'react-redux';
import { useModal } from './ModalContext';

const PredictionModal: React.FC<any> = ({ openModal, closeModal1, isModalOpen1 }) => {

    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    const { isConsentOpen, closeConsentModal } = useModal();
    
    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
    };

    const HandleConsent = async () => {
        closeConsentModal();
        dispatch(handleConsent());
    }

    return (
        <div>

            {isConsentOpen && (
                <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50">
                    <div className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"></div>

                    <div className="modal-container bg-[#545454] w-11/12 md:max-w-md mx-auto border-radius shadow-lg z-50 overflow-y-auto" style={{ background: 'rgb(16 16 16 / 79%)' }}>
                        {/* Add your modal content here */}
                        <div className="modal-content py-4 text-left px-6">
                            <div className="text-xl font-bold mb-4 p-6">
                                <div className="flex items-center justify-center">
                                    <div className="w-10 h-10 mr-2">
                                        <img src={Exclam} alt="modal" className="w-full h-auto" />
                                    </div>
                                    <div className=" text-center">
                                        <span className="text-2xl font-bold text-[#DBDBDB]">IMPORTANT</span>
                                    </div>
                                </div>

                            </div>


                            <div className="mb-4">
                                <ol className='list-decimal pl-6 mb-16'>
                                    <li className='mb-3 text-[#9B9B9B]'>
                                        <p className='text-justify'>
                                        Betracle  AI predictions are for informational purposes
                                        only and do not guarantee match outcomes. Users are
                                        encouraged to exercise responsible decision-making and
                                        perform their own due diligence before acting upon these
                                        predictions.
                                        </p>
                                    </li>
                                    <li className='mb-3 text-[#9B9B9B]'>
                                        Each market prediction costs 1 prediction point.
                                    </li>
                                </ol>
                                <div className="flex items-center space-x-4">
                                    <input type="checkbox" id="agree" className="form-checkbox checked:bg-[#00FF87] h-5 w-5" checked={isChecked}
                                        onChange={handleCheckboxChange} />
                                    <label htmlFor="agree" className="text-[#9B9B9B]">I have read and agree to the above  content</label>
                                </div>

                            </div>

                            <button disabled={!isChecked} onClick={HandleConsent} className={`${isChecked ? 'bg-[#00FF87]' : 'bg-[#151515]'}  ${isChecked ? 'text-[#000000]' : 'text-[#545454]'}  font-[Oxta] font-[300] text-[1rem] py-4 px-4 w-full border-radius`}>
                                Predict
                            </button>


                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PredictionModal;
