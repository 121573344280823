import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: {},
    isFetching: false,
    error: false,
    consent: false,
    disclaimer: false,
    onBoarding: false,
    predictionPoint: 0,
    totalPredictionPoint: 0,
    errorStatusCode: 0
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = false;
      state.predictionPoint = action.payload.user.predictionPoint
      state.totalPredictionPoint = action.payload.user.totalPredictionPoint
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
     
    },
    logout: (state) => {
      console.log(state)
      state.totalPredictionPoint = 0;
      state.predictionPoint = 0;
      state.currentUser = {};
      state.isFetching = false;
      state.error = false;
      state.consent = false;
      state.errorStatusCode = 0;
    },
    handleConsent: (state) => {
      state.consent = true;
      state.error = false;
    },
    handleDisclaimer: (state) => {
      state.disclaimer = true;
      state.error = false;
    },
    handleOnBoarding: (state) => {
      state.onBoarding = true;
      state.error = false;
    },
    handleDecreasePredictionPoint: (state) => {
      if (state.predictionPoint !== 0) {
       state.predictionPoint -= 1;
       state.totalPredictionPoint += 1;
      }
      else {
        return;
      }
    },
    handleUpdatePredictionPoint: (state, action) => {
       state.predictionPoint = action.payload;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout, handleConsent, handleDecreasePredictionPoint, handleDisclaimer, handleOnBoarding, handleUpdatePredictionPoint } = userSlice.actions;
export default userSlice.reducer;
