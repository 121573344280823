import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {
  const user = useSelector((state: any) => state.user.currentUser);
    return(
        user?.token ? <Outlet/> : <Navigate to="/loading-data"/>
    )
}

export default PrivateRoutes;
