import React, { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChangePassword } from '../../Redux/apiCalls';
import { useSelector } from 'react-redux';
import Loader from '../../Utils/Loader/Loader';
import EyeOpen from '../../asset/eye.svg';
import EyeSlash from '../../asset/eye-off.svg';
import { useModal } from './ModalContext';

const ChangePasswordModal: React.FC<any> = ({ openModal, closeModal1, isModalOpen1 }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const user = useSelector((state: any) => state.user.currentUser);
    const { isModalOpen, closeModal } = useModal();

    const togglePasswordVisibility = (e: any) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = (e: any) => {
        e.preventDefault();
        setShowConfirmPassword(!showConfirmPassword);
    };

    const toggleNewPasswordVisibility = (e: any) => {
        e.preventDefault();
        setShowNewPassword(!showNewPassword);
    };


    const handleChangePassword = async (e: any) => {
        e.preventDefault();

        setIsLoading(true);
        if (currentPassword === '' || newPassword === '' || confirmPassword === '') {
            toast.warn("All fields are required.");
            setIsLoading(false);
        }
        else if (newPassword !== confirmPassword) {
            toast.warn("Password do not match.");
            setIsLoading(false);
        }
        else {
            let data = {
                oldPassword: currentPassword,
                newPassword: newPassword
            }

            try {
                const _Tmp = await ChangePassword(data, user.user.id);

                if (_Tmp.data.message === 'Successful') {
                    toast.success("Password updated successfully");
                    setIsLoading(false);
                    closeModal();
                    setShowConfirmPassword(false);
                    setShowPassword(false);
                    setShowNewPassword(false);
                    setNewPassword('');
                    setConfirmPassword('');
                    setCurrentPassword('');
                }

                if (_Tmp.data.message === 'Auth failed') {
                    toast.warn("Password is not correct");
                    setIsLoading(false);
                }
            } catch (error) {
                toast.warn("An error has occurred");
                setIsLoading(false);
            }
        }
    }


    const handleCloseModal = () => {
        closeModal();
        setShowConfirmPassword(false);
        setShowPassword(false);
        setShowNewPassword(false);
        setNewPassword('');
        setConfirmPassword('');
        setCurrentPassword('');
    }


    return (
        <div>

            {isModalOpen && (
                <div className="fixed w-full h-full inset-0 flex items-center justify-center z-50">
                    <div onClick={handleCloseModal} className="modal-overlay absolute w-full h-full bg-[#000000] opacity-75 z-10"></div>

                    <ToastContainer autoClose={5000} />
                    {isLoading && <Loader />}
                    <div className="modal-container  w-11/12 md:max-w-md mx-auto border-radius shadow-lg overflow-y-auto absolute bg-transparent glass-card mx-auto z-50 overflow-y-auto account-modal border-[1px] border-[#2A2A2A] border-radius" style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>

                        <div className="modal-content py-4 text-left px-6">



                            <div className="my-12 relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                                    placeholder='Enter current password'
                                    style={{ background: 'rgb(20 20 20 / 79%)', fontSize: '12px' }}
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />

                                <button
                                    className="absolute inset-y-0 right-0 px-3 py-2"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ?
                                        <img src={EyeSlash} alt="eye slash" className="w-6 h-6" />
                                        :
                                        <img src={EyeOpen} alt="eye open" className="w-6 h-6" />
                                    }
                                </button>
                            </div>

                            <div className="my-12 relative">
                                <input
                                    type={showNewPassword ? 'text' : 'password'}
                                    className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                                    placeholder='Enter new password'
                                    style={{ background: 'rgb(20 20 20 / 79%)', fontSize: '12px' }}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />

                                <button
                                    className="absolute inset-y-0 right-0 px-3 py-2"
                                    onClick={toggleNewPasswordVisibility}
                                >
                                    {showNewPassword ?
                                        <img src={EyeSlash} alt="eye slash" className="w-6 h-6" />
                                        :
                                        <img src={EyeOpen} alt="eye open" className="w-6 h-6" />
                                    }
                                </button>

                            </div>

                            <div className="my-12 mb-24 relative">
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                                    placeholder='Repeat new Password'
                                    style={{ background: 'rgb(20 20 20 / 79%)', fontSize: '12px' }}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />

                                <button
                                    className="absolute inset-y-0 right-0 px-3 py-2"
                                    onClick={toggleConfirmPasswordVisibility}
                                >
                                    {showConfirmPassword ?
                                        <img src={EyeSlash} alt="eye slash" className="w-6 h-6" />
                                        :
                                        <img src={EyeOpen} alt="eye open" className="w-6 h-6" />
                                    }
                                </button>
                            </div>

                            <div className="mb-4 ">
                                <button
                                    type="submit"
                                    className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[1rem] py-4 px-4 w-full border-radius"
                                    onClick={handleChangePassword}
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Processing..." : "Change Password"}
                                </button>
                            </div>




                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChangePasswordModal;


// import React from 'react';
// import { useModal } from './ModalContext';

// const ChangePasswordModal: React.FC = () => {
//     const { isModalOpen, closeModal } = useModal();

//     if (!isModalOpen) return null;

//     return (
//         <>
//             <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50 mr-4">

//                 <div className="modal-overlay absolute top-[64px] w-full h-full bg-[#000000] opacity-50">
//                     <div className='absolute bg-[#00170C] w-12 h-12 flex items-center justify-center glass-card right-[60%] md:right-[17%] -top-8 z-10' style={{ borderRadius: '50%', boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
//                         <h1 className='text-[#D9D9D9] text-[0.7rem] md:text-[1.5rem]'>1</h1>
//                     </div>
//                 </div>

//                 <div className="modal-container top-[100px] right-10 w-[72%] md:w-[25%]  absolute bg-transparent glass-card mx-auto border-radius shadow-lg z-50 overflow-y-auto account-modal border-[1px] border-[#2A2A2A] border-radius" style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}
//                 >

//                     <div className="modal-content py-4 text-left px-6">
//                         {/* <div className='relative bg-[#00170C] w-12 h-12 flex items-center justify-center glass-card' style={{ borderRadius: '50%'}}>
//             <h1 className='text-[#D9D9D9] text-[0.7rem] md:text-[1.5rem]'>1</h1>
//         </div> */}

//                         <h1 className='text-right text-[#545454] mb-10 text-[0.6rem] md:text-[0.7rem]'>1/5</h1>

//                         <div className='mb-10'>
//                             <h1 className='text-[#00FF87] font-extrabold font-[Orbitron] mb-2 text-[0.7rem] md:text-[0.9rem]'>Welcome to Betracle AI</h1>
//                             <p className='text-[0.6rem] md:text-[0.8rem] text-[#A2A1A1]'>
//                                 this is your profile menu, it gives you access
//                                 to different options related to your account and
//                                 other important features.
//                             </p>
//                         </div>


//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default ChangePasswordModal;
