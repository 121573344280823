import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const InactivityHandler = ({ logout }: any) => {
  const history = useNavigate();
  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);

  // Timeout in milliseconds (10 minutes)
  const INACTIVITY_LIMIT = 10 * 60 * 1000;

  // Function to log the user out
  const handleLogout = () => {
    console.log('User has been inactive for 10 minutes. Logging out...');
    logout(); // Call the logout function (e.g., clear session, redirect to login)
    history('/'); // Redirect to the login page
  };

  // Reset the inactivity timer
  const resetInactivityTimer = () => {
    // Clear the previous timer
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    
    // Set a new timer for the inactivity limit
    inactivityTimer.current = setTimeout(handleLogout, INACTIVITY_LIMIT);
  };

  // Add event listeners for user activity
  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'click'];
    
    // Reset timer on user activity
    events.forEach(event => window.addEventListener(event, resetInactivityTimer));

    // Start the initial timer
    resetInactivityTimer();

    // Clean up event listeners when the component unmounts
    return () => {
      events.forEach(event => window.removeEventListener(event, resetInactivityTimer));
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
    };
  }, []);

  return null; // No UI needed, this is just handling the logic
};

export default InactivityHandler;
