
import { useSelector } from 'react-redux';
import Fire from '../asset/noto_fire.png';
import CopyPaste from '../asset/copypaste.png';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { useEffect, useState } from 'react';
import { GetCorrectPredictions } from '../Redux/apiCalls';


// formatNumber(number) {
//   if (number >= 1000000) {
//       return (number / 1000000).toFixed(1) + 'M';
//   } else if (number >= 1000) {
//       return (number / 1000).toFixed(1) + 'K';
//   } else {
//       return number.toString();
//   }
// }


export default function TotalPredictionsAndCorrectPredictions() {
  const Tp = useSelector((state: any) => state.user.totalPredictionPoint);
  const [correctPrediction, setCorrectPrediction] = useState(0);

  useEffect(() => {
    GetPredictions();
  })

  const GetPredictions = async () => {
    try {
      const response = await GetCorrectPredictions(); 
      if (response) {
        setCorrectPrediction(response.info);
      }
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className="mt-2 border-[1px] border-[#2A2A2A] border-radius rounded-lg p-2 md:p-5">
      {/* New Section */}

      <Splide options={
        {
          perPage: 1,
          focus: 0,
          lazyLoad: 'nearby',
          pagination: true,
          arrows: false,
          gap: '1rem',
          breakpoints: {
            640: {
              perPage: 1,
              gap: '0.1rem',
              pagination: false,
            },
          },
          autoplay: true,
          type: 'loop',

        }
      } aria-label="My Slides">


        <SplideSlide className='rounded-lg cursor-pointer' >
          <div className="mt-0 bg-[#0B0B0B] p-5 border-radius rounded-lg" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A' }}>
            <div className="text-left">
              <div className="flex items-center mb-3">
                <img src={Fire} alt="Icon" className="w-6 h-6 md:w-6 md:h-6 mr-1" />
                <p className="text-[0.9rem] md:text-[1rem] text-[#838383] font-[200]">Total Predictions</p>
              </div>
              <p className="text-[#6F6F6F] text-3xl md:text-4xl font-extrabold font-[Orbitron] ">{Tp}</p>
            </div>
          </div>
        </SplideSlide>


        <SplideSlide className='rounded-lg cursor-pointer' >
          <div className="mt-0 bg-[#0B0B0B] p-5 border-radius rounded-lg" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A' }}>
            <div className="text-left">
              <div className="flex items-center mb-3">
                <img src={CopyPaste} alt="Icon" className="w-6 h-6 md:w-6 md:h-6 mr-1" />
                <p className="text-[0.9rem] md:text-[1rem] text-[#838383] font-[200]">Correct Predictions</p>
              </div>
              <p className="text-[#6F6F6F] text-3xl md:text-4xl font-extrabold font-[Orbitron]">{correctPrediction}</p>
            </div>
          </div>
        </SplideSlide>


      </Splide>




    </div>
  )
}
