import React, { useState } from 'react'
import CloseIcon from '../asset/close.png';
import Exclude from '../asset/Logo12.png';
import SBET from '../asset/SBet.jpg';
import Light from '../asset/lighting.png';
import Users from '../asset/users12.png';
import { AirDropBonusInfo } from './AirDropBonusInfo';
import { TotalReferrals } from './TotalReferrals';
import { PredictionPoints } from './PredictionPoints';
import { BuyPredictionPoint } from './BuyPredictionPoint';
import TotalPredictionsAndCorrectPredictions from './TotalPredictionAndCorrectPredictions';
import { LOCK_FEATURE } from '../Utils/utils';

export const Menu: React.FC<any> = ({ toggleSidebar }) => {
    const [activeTab, setActiveTab] = useState('Predictions');


    const handleTabClick = (tabId: any) => {
        setActiveTab(tabId);
    };

    return (
        <div className='bg-black bg-opacity-50 glass-card h-full border-[1px] border-[#2A2A2A]'>
            <div className="flex justify-between items-center  p-3 md:hidden">
                <div className="flex items-center justify-start">
                    <a href="#" className='flex items-center'>
                        <img src={Exclude} alt="BLogo" className="w-40 h-16" />
                        {/* <img src={SBET} alt="BLogo" className="w-40 h-16" /> */}
                    </a>
                </div>

                <div className='' onClick={toggleSidebar}>
                    <img src={CloseIcon} alt="BLogo" className="w-12 h-12" />
                </div>
            </div>

            <div>
                <div className='p-3'>
                    <ul className="top-0 w-full z-10 flex flex-wrap justify-between -mb-px text-sm font-medium text-center bg-[#080808] border-[1px] border-[#2A2A2A] border-radius p-4 md:p-0" id="myTab" role="tablist">
                        <li className="mr-4" role="presentation">
                            <button
                                className={`inline-block m-0 md:m-2 p-4 ${activeTab === 'Predictions' ? 'bg-[#00FF87] text-[#000000] font-[900]' : 'bg-[#151515] text-[#545454] font-[300]'} rounded-lg text-[0.8rem] md:text-[1rem] flex items-center`}
                                onClick={() => {
                                    handleTabClick('Predictions');
                                }}
                                role="tab"
                                aria-controls="Predictions"
                                aria-selected={activeTab === 'Predictions'}
                                style={{ boxShadow: '4px 4px 0 0 #000000' }}
                            >
                                <img src={Light} alt="Icon" className="w-6 h-6 mr-2" />
                                Predictions
                            </button>
                        </li>
                        <li className="mr-2" role="presentation">
                            <button
                                className={`inline-block m-0 md:m-2 p-4 ${activeTab === 'Referrals' ? 'bg-[#00FF87] text-[#151515] font-[900]' : 'bg-[#151515] text-[#545454] font-[300]'} rounded-lg text-[0.8rem] md:text-[1rem] flex items-center`}
                                onClick={() => {
                                    handleTabClick('Referrals')
                                }}
                                role="tab"
                                aria-controls="Referrals"
                                aria-selected={activeTab === 'Referrals'}
                                style={{ boxShadow: '4px 4px 0 0 #000000' }}
                            >
                                <img src={Users} alt="Icon" className="w-6 h-6 mr-2" />
                                Referrals
                            </button>
                        </li>


                    </ul>
                </div>

                <div className={`p-1 m-3 border-radius ${activeTab === 'Predictions' ? '' : 'hidden'}`} >
                    <PredictionPoints />
                    <div className='my-4'></div>
                    {LOCK_FEATURE && <BuyPredictionPoint />}
                    {LOCK_FEATURE && <div className='my-4'></div>}
                    <TotalPredictionsAndCorrectPredictions />
                </div>

                <div className={`p-1 m-3 border-radius ${activeTab === 'Referrals' ? '' : 'hidden'}`} >
                     <AirDropBonusInfo />

                    <div className='my-8'></div>

                     <TotalReferrals />
                </div>

            </div>
        </div>
    )
}
