
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LayoutPage from "./Screens/LayoutPage";
import AuthenticationPage from "./Screens/Authentication/AuthPage";
import ResetPasswordFeature from "./Screens/Authentication/Components/ResetPassword";
import LoadingData from "./Utils/LoadingData";
import VerifyEmailPage from "./Screens/Authentication/Components/VerifyEmailPage";
import PrivateRoutes from "./Utils/privateRoutes";
import { AirDropIndexPage } from "./Screens/AirDrops/Index";
import { useEffect } from "react";
import { ModalProvider } from "./Components/Modals/ModalContext";
import InactivityHandler from "./Utils/InActivityHandler";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from "./Redux/userRedux";

function App() {
  const location = useLocation();
  const router = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/home') {
      window.location.replace('/loading-data');
    }
  }, []);

  const Logout = () => {
    router('/');
    dispatch(logout());
    localStorage.clear();
  
}

  return (
    <div>
      <ModalProvider>
      <InactivityHandler logout={Logout} />
        <Routes>
          <Route path="/" element={<AuthenticationPage />} />
          <Route path='/signup/ref/:username' element={<AuthenticationPage />} />
          <Route path="/resetPassword/:token" element={<ResetPasswordFeature />} />
          <Route path="/confirmation/:email/:token" element={<VerifyEmailPage />} />

          <Route path="/loading-data" element={<LoadingData />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/home" element={<LayoutPage />} />
            <Route path="/airdrop" element={<AirDropIndexPage />} />
          </Route>
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </ModalProvider>

    </div>
  );
}

export default App;
