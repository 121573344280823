import React from 'react'
import TotalPredictionsAndCorrectPredictions from './TotalPredictionAndCorrectPredictions'
import { PredictionPoints } from './PredictionPoints'
import { BuyPredictionPoint } from './BuyPredictionPoint'
import { motion } from 'framer-motion';
import AccountCard from './AccountCard';
import { LOCK_FEATURE } from '../Utils/utils';

export const RightSideBar = () => {
    return (
        <aside className=" text-white w-1/5 hidden lg:block p-3 fixed top-0 right-0 h-full mr-10">
            <nav>
            <AccountCard />

            <div className='mt-10'></div>
            
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.5 }}
                    
                >
                    <TotalPredictionsAndCorrectPredictions />
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 2.5 }}
                    
                >
                    <PredictionPoints />
                </motion.div>

                {!LOCK_FEATURE && <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 3.5 }}
                   
                >
                    <BuyPredictionPoint />
                </motion.div>}

                
            </nav>
        </aside>
    )
}
