import axios from "axios";
import { loginFailure, loginStart, loginSuccess } from "./userRedux";
import {
  fetchGamesFailure,
  fetchGamesStart,
  fetchGamesSuccess,
} from "./gamesRedux";
import { Utils } from "../Utils/utils";

// axios.defaults.baseURL = "http://localhost:8000/api/v1/";
// axios.defaults.timeout = 10000;

//const ONLINE_URL = "http://localhost:8000/api/v1/";
const ONLINE_URL = "https://api.betracle.ai/api/v1/";
const AxiosConfig = {
  timeout: 20000,
};

export const login = async (dispatch: any, email: string, password: string) => {
  dispatch(loginStart());
  
  try {
    const data = {
      email: email,
      password: password,
    };

    const url = `${ONLINE_URL}login`;
    const response = await axios.post(url, data, AxiosConfig);

    if (response.status === 200) {
      Utils.errorStatusCode = 200;
      const userUri = `${ONLINE_URL}user/${response.data.user.id}`;
      
      try {
        const userResponse = await axios.get(userUri);
        
        if (userResponse.status === 200) {
          Utils.errorStatusCode = 200;
          localStorage.setItem("USER_DETAILS", JSON.stringify(userResponse.data.user));
          dispatch(loginSuccess(response.data));
          Utils.PredictionPoint = response.data.user.predictionPoint;
        } else {
          Utils.errorStatusCode = 0;
          dispatch(loginFailure());
          //console.error("Failed to fetch user details, status code:", userResponse.status);
        }
        
      } catch (userError: any) {
        Utils.errorStatusCode = 0;
        dispatch(loginFailure());
       // console.error("Error fetching user details:", userError.message);
      }
      
    } else {
      Utils.errorStatusCode = 0;
      dispatch(loginFailure());
      //console.error("Login request failed, status code:", response.status);
    }

  } catch (error: any) {
    if (error.code === "ERR_NETWORK") {
      Utils.errorStatusCode = 502;
    } else if (error.response) {
      Utils.errorStatusCode = error.response.status;
    } else {
      Utils.errorStatusCode = 500; // Internal server error as a fallback
    }
    
    dispatch(loginFailure());
    console.error("Login error:", error.message, "Code:", error.code);
  }
};



export const Register = async (data: any) => {
  try {
    let url = `${ONLINE_URL}register`;
    const info = await axios.post(url, data, AxiosConfig);
    return info;
  } catch (error) {
    throw error;
  }
};

export const ChangePassword = async (data: any, userId: any) => {
  try {
    let url = `${ONLINE_URL}updatePassword/${userId}`;
    const info = await axios.put(url, data, AxiosConfig);
    return info;
  } catch (error) {
    throw error;
  }
};

export const ForgotPasswordApi = async (email: string) => {
  try {
    let data = {
      email: email,
    };
    let url = `${ONLINE_URL}forgotPassword`;
    const info = await axios.post(url, data, AxiosConfig);
    return info;
  } catch (error) {
    throw error;
  }
};

export const ResetPassword = async (token: any, data: any) => {
  try {
    let url = `${ONLINE_URL}reset-password/${token}`;
    const info = await axios.put(url, data, AxiosConfig);
    return info;
  } catch (error) {
    throw error;
  }
};

// export const FetchGames =  async (dispatch: any) => {
//   dispatch(fetchGamesStart());
//   try {
//     const response = await axios.get('http://localhost:8080/api/v1/all-games');
//     console.log(response.data);
//     dispatch(fetchGamesSuccess(response.data))
//   } catch (error:any) {
//     dispatch(fetchGamesFailure(error.message))
//     throw error;
//   }
// };

export const FetchGames = async () => {
  try {
    let url = `${ONLINE_URL}all-games`;
    const response = await axios.get(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const FetchUsers = async () => {
  try {
    let url = `${ONLINE_URL}users`;
    const response = await axios.get(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const FetchCarousels = async () => {
  try {
    let url = ONLINE_URL + "carousels";
    const response = await axios.get(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const NewCarousel = async (payload: any) => {
  try {
    let url = ONLINE_URL + "carousel";

    const response = await axios.post(url, payload, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const UpdateCarousel = async (carouselId: any, payload: any) => {
  try {
    let url = ONLINE_URL + "carousel/update/" + carouselId;
    const response = await axios.put(url, payload, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const DeleteCarousel = async (carouselId: any) => {
  try {
    let url = ONLINE_URL + "carousel/" + carouselId;
    const response = await axios.delete(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const GetSingeUserWithUsername = async (username: any) => {
  try {
    let url = ONLINE_URL + "username/" + username;
    const response = await axios.get(url, {
      timeout: 3000,
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const AddReferral = async (payload: any) => {
  try {
    let url = ONLINE_URL + "addreferral";
    const response = await axios.post(url, payload, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const DecreasePredictionPoint = async (userId: number, payload: any) => {
  try {
    let url = ONLINE_URL + "decrease/prediction/" + userId;
    const response = await axios.put(url, payload, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const GetAllMarkets = async () => {
  try {
    let url = ONLINE_URL + "markets";
    const response = await axios.get(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const UpdateMarket = async (marketId: number, payload: any) => {
  try {
    let url = ONLINE_URL + "market/update/" + marketId;
    const response = await axios.put(url, payload, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const DeleteMarket = async (marketId: number) => {
  try {
    let url = ONLINE_URL + "market/delete/" + marketId;
    const response = await axios.delete(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const NewMarket = async (payload: any) => {
  try {
    let url = ONLINE_URL + "newMarket";
    const response = await axios.post(url, payload, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const SendVerificationEmail = async (payload: any) => {
  try {
    let url = ONLINE_URL + "send-verification-email";
    let data = {
      email: payload,
    };
    const response = await axios.post(url, data, {
      timeout: 120000,
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const VerifyEmailMessage = async (token: any) => {
  try {
    let url = ONLINE_URL + "verify-email/" + token;
    const response = await axios.put(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const GetPredictionHistory = async (userId: any) => {
  try {
    let url = ONLINE_URL + "history/" + userId;
    const response = await axios.get(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const GetCorrectPredictions = async () => {
  try {
    let url = ONLINE_URL + "correct-predictions";
    const response = await axios.get(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const NewHistory = async (payload: any) => {
  try {
    let url = ONLINE_URL + "history";
    const response = await axios.post(url, payload, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const ClaimBonus = async (userId: any) => {
  try {
    let url = ONLINE_URL + `claim-bonus/${userId}`;
    const response = await axios.put(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const SaveViewedMarket = async (payload: any) => {
  try {
    let url = ONLINE_URL + "save-viewed-markets";
    const response = await axios.post(url, payload, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const GetViewedMarket = async (userId: any) => {
  try {
    let url = ONLINE_URL + "viewed-markets/" + userId;
    const response = await axios.get(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const NewPaymentTransaction = async (payload: any) => {
  try {
    let url = ONLINE_URL + "purchase-points"
    const response = await axios.post(url, payload, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const GetTransactions = async (userId: any) => {
  try {
    let url = ONLINE_URL + "transactions/" + userId;
    const response = await axios.get(url, AxiosConfig);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
