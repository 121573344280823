import React from 'react'
import BadgeImage from '../../../asset/badge.png';
import FireImage from '../../../asset/noto_fire.png';


const users: any[] = [
    // { id: 1, username: "user1", earned: 100 },
    // { id: 2, username: "user2", earned: 200 },
    // { id: 3, username: "user3", earned: 300 },
    // { id: 4, username: "user4", earned: 400 },
    // { id: 5, username: "user5", earned: 500 },
    // { id: 6, username: "user6", earned: 600 },
    // { id: 7, username: "user7", earned: 700 },
    // { id: 8, username: "user8", earned: 800 },
    // { id: 9, username: "user9", earned: 900 },
    // { id: 10, username: "user10", earned: 1000 }
];

export const AirDropTopUsers = () => {
    return (
        <div className='mt-4'>
            <div className="mt-0 bg-[#000000] p-5 border-[1px] border-[#2A2A2A] border-radius rounded-lg" style={{ boxShadow: '0px 0px 0px 0px #2A2A2A' }}>
                <div className="text-left">
                    <div className="flex items-center justify-between mb-10">
                        <div className='flex p-3 bg-[#666767] border-radius rounded-lg'>
                            <img src={BadgeImage} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-5" />
                            <span className='text-[#C6C6C6] text-[0.7rem] md:text-[1rem]'>Top 10</span>
                        </div>


                        <div>
                            <div className='flex items-center'>
                                <img src={FireImage} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-2" />
                                <span className='text-[#C6C6C6] text-[0.7rem] md:text-[0.8rem]'>Reward</span>
                            </div>
                            <span className=' font-bold text-[#00FF87] text-[0.9rem] md:text-[1.4rem] font-extrabold font-[Orbitron]'>
                                +600 xp
                            </span>
                        </div>
                    </div>


                    <div className='flex items-center justify-between text-[#545454] text-[0.7rem] md:text-[0.8rem] p-2 mb-3'>
                        <div className='flex items-center space-x-4'>
                            <span>#</span>
                            <span>Username</span>
                        </div>

                        <span>XP Earned</span>
                    </div>

                    {
                        users.map((user: any, i: number) => (
                            <div className={`flex items-center justify-between text-[0.7rem] md:text-[0.8rem]  p-2 ${i % 2 === 0 ? 'bg-black' : 'bg-[#545454]'}`}>
                                <div className='flex items-center space-x-4'>
                                    <span className='text-[#D9D9D9]'>{i + 1}</span>
                                    <span className='text-[#D9D9D9]'>{user.username}</span>
                                </div>
                                <span className='text-[#D9D9D9]'>{user.earned}</span>
                            </div>
                        ))


                    }
                    {/* <div className={`flex items-center justify-between text-[0.7rem] md:text-[0.8rem]  p-2 bg-[#00170C] mt-[2rem]`}>
                        <div className='flex items-center space-x-4'>
                            <span className='text-[#00FF87]'>200</span>
                            <span className='text-[#00FF87]'>Abdullahi</span>
                        </div>
                        <span className='text-[#00FF87]'>60</span>
                    </div> */}

                </div>
            </div>
        </div>
    )
}
