import React from 'react'
// import NoPrediction from './NoPrediction';

import { motion } from 'framer-motion';

const CS: React.FC<any> = ({ game, team }) => {
    const prediction = (game !== null && game !== '') ? JSON.parse(game) : null;


    return (
        <>
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
            >
                {prediction !== null ? (
                    // Round probabilities to the nearest whole number
                    (() => {
                        const homeProbability = Math.round(prediction.home.probability);
                        const awayProbability = Math.round(prediction.away.probability);

                        let homeProb = homeProbability >= 50 ? 'text-[#00FF87]' : 'text-[#DD2E44]';
                        let awayProb = awayProbability >= 50 ? 'text-[#00FF87]' : 'text-[#DD2E44]';


                        // Determine colors based on probabilities
                        let homeColor;
                        let awayColor;

                        if (prediction.home.prediction > prediction.away.prediction) {
                            homeColor = 'text-[#00FF87]';
                            awayColor = 'text-[#F44336]';
                        } else if (prediction.home.prediction < prediction.away.prediction) {
                            homeColor = 'text-[#F44336]';
                            awayColor = 'text-[#00FF87]';
                        } else {
                            homeColor = 'text-[#FF9800]';
                            awayColor = 'text-[#FF9800]';
                        }

                        return (
                            <div className='border-[1px] border-[#2A2A2A] border-radius rounded-lg w-[18rem] flex justify-between bg-transparent glass-card' style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}>
                                <div className="mt-2 ml-3 mb-3 flex items-center">
                                    <img src={team.homeLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-1" />
                                    <p className={`text-[1rem] md:text-2xl font-extrabold font-[Orbitron] ${homeColor}`}>
                                        {prediction.home.prediction}
                                    </p>
                                </div>

                                <div className="mt-2 mb-3 flex items-center">
                                    <img src={team.awayLogo} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-1" />
                                    <p className={`text-[1rem] md:text-2xl font-extrabold font-[Orbitron] ${awayColor}`}>
                                        {prediction.away.prediction}
                                    </p>
                                </div>

                                <div className="mt-2 mb-3 mr-3">
                                    <p className='text-[#545454] text-[0.5rem] md:text-[0.7rem]'>Probability</p>
                                    <p className={`text-[1rem] md:text-2xl font-extrabold font-[Orbitron] ${homeProbability >= awayProbability ? homeProb : awayProb}`}>
                                        {homeProbability >= awayProbability ? homeProbability : awayProbability}<span className="-top-2 -right-2 text-base">%</span>
                                    </p>
                                </div>
                            </div>
                        );
                    })()
                ) : (
                   null
                )}
            </motion.div>

        </>
    )
}

export default CS;
