import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FetchGames } from '../Redux/apiCalls';
import { fetchGamesFailure, fetchGamesStart, fetchGamesSuccess } from '../Redux/gamesRedux';
import { useNavigate } from 'react-router-dom';
import Loader1 from './Loader1';
import { logout } from '../Redux/userRedux';
import { Utils } from './utils';
import Loader from './Loader/Loader';

export default function LoadingData() {
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector((state: any) => state.user);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log(Utils.errorStatusCode)
        // console.log(user.currentUser)

        if (user.currentUser && Object.keys(user.currentUser).length === 0) {
            setTimeout(() => {
                Logout()
            }, 3000)
        } else {
            GetGames();
        }

    }, []);

    const GetGames = async () => {
        try {

            setIsLoading(true);
            dispatch(fetchGamesStart())
            const _Tmp = await FetchGames();

            if (_Tmp.response.length > 0) {
                dispatch(fetchGamesSuccess(_Tmp.response));
                setTimeout(() => {
                    navigate('/home');
                }, 3000);
                setIsLoading(false);

            }
            else {
                Logout();
                //console.log("No games found")
                setIsLoading(false);
                dispatch(fetchGamesFailure());
                return;
            }
        } catch (error) {
            Logout();
            //console.log(error)
            dispatch(fetchGamesFailure());
            setIsLoading(false);
        }
    }

    const Logout = () => {
        dispatch(logout());
        localStorage.clear();
        navigate('/');
    }

    return (
        <>
            {/* {
            isLoading ? <div className="loadData"></div> : <Navigate to='/home' />
        } */}

            <div className="flex justify-center items-center h-screen">
                <Loader />
                {/* <div className="flex justify-center items-center mt-6">
                    <div className="load"></div>
                </div> */}
            </div>
        </>
    )
}
