import React from 'react'
import Exclude from '../../../asset/Logo12.png';
import AccountCard from '../../../Components/AccountCard';
import { useNavigate } from 'react-router-dom';
import AccountImage from '../../../asset/account12.png';
import Hamburger from '../../../asset/hamburger.png';
import { useSelector } from 'react-redux';

export const AirDropHeader: React.FC<any> = ({ toggleSidebar }) => {
    const router = useNavigate();
    const user = useSelector((state: any) => state.user.currentUser);


    return (
        <header className="flex justify-between items-center bg-[#00000] p-3">

            <div className='hidden md:block'>
                <img src={Exclude} alt="BLogo" className="w-30 h-24" />
            </div>


            {/* <div className='p-2 border-[1px] border-[#2A2A2A] border-radius rounded-lg'>
                <div className='flex justify-between w-[10rem] md:w-[27rem] bg-[#0B0B0B] p-2 md:p-5 border-[1px] border-[#2A2A2A] border-radius rounded-lg'>
                    <img src={AccountImage} alt="Icon" className="w-4 h-4 md:w-6 md:h-6" />
                    <span className="text-[0.9rem] md:text-[1rem] text-[#838383] font-[200]">
                        Account
                    </span>
                </div>
            </div> */}

            <div className="flex items-center justify-start md:hidden">
                <a href="#" className='flex items-center'>
                    <img src={Exclude} alt="BLogo" className="w-40 h-16" />
                </a>
            </div>

            <div className='w-[24%]'>
                {user.token && <AccountCard />}

                {!user.token && <div className="flex items-center justify-start md:hidden">
                <a href="#" className='flex items-center'>
                    <img src={Exclude} alt="BLogo" className="w-40 h-16" />
                </a>
            </div>}
            </div>

            <div className='md:hidden block' onClick={toggleSidebar}>
                <img src={Hamburger} alt="BLogo" className="w-10 h-10" />
            </div>

        </header>

    )
}



{/* <header className="flex justify-between items-center mr-0">

<span onClick={() => {
    router('/home');
}} className='flex items-center'>
    <img src={Exclude} alt="BLogo" className="w-50 h-16" />

</span>


<AccountCard />
</header> */}

