import React, { useState } from 'react';
import { useModal } from './ModalContext';

const OutOfPointModal: React.FC<any> = ({  closeModal, isModalOpen }) => {

    const [isChecked, setIsChecked] = useState(false);
    const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);
    const { openPaymentModal } = useModal();

    const openAddPaymentModal = () => {
        setIsAddPaymentOpen(true);
    };

    const closeAddPaymentModal = () => {
        setIsAddPaymentOpen(false);
    };

    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
    };

    const HandleAddPayment = () => {
        closeModal();
        // openAddPaymentModal();
        openPaymentModal();
    }
    return (
        <div>

            {isModalOpen && (
                <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50">
                    <div onClick={closeModal} className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"></div>

                    <div className="modal-container bg-[#545454] w-11/12 md:max-w-md mx-auto border-radius shadow-lg z-50 overflow-y-auto" style={{ background: 'rgb(16 16 16 / 79%)' }}>
                        
                        <div className="modal-content py-4 text-left px-6">
                            <div className="text-xl font-bold mb-4 p-3">
                                <div className="flex items-center">
                                    {/* <div className="w-10 h-10 mr-2">
                                        <img src={Exclam} alt="modal" className="w-full h-auto" />
                                    </div> */}
                                    <div className=" text-center">
                                        <span className="text-2xl font-bold text-[#8D8D8D]">Oops...</span>
                                    </div>
                                </div>

                            </div>


                            <div className="mb-4">
                                <p className='text-[#8D8D8D]'>you seem to be out of prediction points.</p>
                            </div>

                            <button onClick={HandleAddPayment} className={`bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] py-4 px-4 w-full border-radius`}>
                            <span className="font-[Oxta] font-[300] text-[1rem] mr-2">Add</span>
                                <svg
                                    className="w-4 h-4 text-[#000000] font-extrabold text-4xl"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                    ></path>
                                </svg>
                            </button>


                        </div>
                    </div>
                </div>
            )}

            {/* <AddPaymentModal closeAddPaymentModal={closeAddPaymentModal} isAddPaymentOpen={isAddPaymentOpen} /> */}
        </div>
    );
}

export default OutOfPointModal;
